import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmA from "src/components/nonbmA"
import Btf_cb from "src/components/btf_cb"
import CtaDisclosureCb2 from "src/components/ctadisclosurecb2"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const nonbmaData = import("./data/nonbmadata.json");
const btfData = import("./data/btfData.json");


export default function previewClear() {

  let css;
  let module = <CtaDisclosureCb2></CtaDisclosureCb2>;
  if(isChrome() || isIeEdge() || isFirefox()){
    module = <CtaDisclosureCb2></CtaDisclosureCb2>;
  }
  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
  background:#fff;
}

.nonbma-module--nonbma .nonbma-module--atfGrid {
	background: url("/img/lp-bg-print.jpg") no-repeat;
  background-size:cover;
}
.nonbma-bf-module--nonbmaBf .nonbma-bf-module--bf {
box-shadow: inset 0 7px 36px -7px rgba(0,0,0,0.2);
}

button.trbm_cta {
  font-weight: normal;
  font-size: 30px;
}

button.install {
  color: #fff;
  background: #FC6422;
  padding: 20px 100px;
  font-size: 35px;
  font-weight: normal;
  margin: 32px auto;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: .2s ease;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
  display: block;
  font-weight:700;
}

button.install:hover {
  transform: scale(1.1);
}

.nonbma-module--nonbma .nonbma-module--install {
  background: #333;
  padding: 20px;
}

#ctadisclosuresearch-module--ctadisclaimersearch, .nonbma-module--nonbma .nonbma-module--br, .nonbma-module--nonbma .nonbma-module--header img {
  display: none;
}

.nonbma-module--nonbma .nonbma-module--header {
  box-shadow: none;
}

.nonbma-module--nonbma ul.nonbma-module--bullets {
  display: none;
}

.nonbma-module--nonbma .nonbma-module--center h3 {
  margin: 10px auto;
  width: 60%;
}

.nonbma-module--nonbma .nonbma-module--center h1 {
  font-size: 45px;
}


`}
   </style>

   <style type="text/css">
        {css}
            </style>

      <title>Save any web page easily - Easy Save</title></Helmet>
      <NonbmA data={nonbmaData}>{module}</NonbmA>
      <Btf_cb data={btfData}></Btf_cb>
    </HomepageLayout>
  )
}
